const appConfig = {
  logoImgSrc: require('@/assets/img/HeiGIT_Crowdmap_Logo_156x90.png'), // The logo used on the top-right corner of the sidebar and on the header menu
  // logoImgSrc: require('@/assets/img/logo@2x.png'), // The logo used on the top-right corner of the sidebar and on the header menu
  showInstructionsTooltipsOnFirstLoad: true,
  appLocales: [{ text: 'English US', value: 'en-us' }],
  defaultLocale: 'en-us',
  footerAppName: 'HeiGIT Crowdmap',
  footerDevelopedByLink: 'https://heigit.org',
  disableProjectAlreadyCompleted: true,
  firebaseConfig: {
    apiKey: "AIzaSyAxIKgU5Yr-DoQ-WCyAvt7Ua3M5T_J7VjI", // crowdmap client api key -- for deployment!!!
    // apiKey: "AIzaSyADDCht3t__R3msAJNTtr7XN9N_OopMNzs", // crowdmap development api key
    authDomain: "heigit-crowdmap.firebaseapp.com",
    databaseURL: "https://heigit-crowdmap-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "heigit-crowdmap",
    storageBucket: "heigit-crowdmap.appspot.com",
    messagingSenderId: "500527298752",
    appId: "1:500527298752:web:73db8f208fca0468355525"    
  },
  projectTypes: {
      '1': {
      component: 'tiles',
      defaultAnswerLabels: [
        { "color": "", "label": "no", "value":  0 },
        { "color": "green", "label": "yes", "value": 1 },
        { "color": "orange", "label": "maybe", "value": 2 },
        { "color": "red", "label": "bad imagery",  "value": 3 },
      ],
    },
    '2': {
      component: 'validate',
      defaultAnswerLabels: [
        { "color": "green", "label": "yes", "value": 1 },
        { "color": "red", "label": "no", "value": 0 },
        { "color": "orange", "label": "offset",  "value": 3 },
        { "color": "gray", "label": "not sure",  "value": 2 },
      ],
    },
    '3': {
      component: 'change',
      defaultAnswerLabels: [
        { "color": "", "label": "no change", "value":  0 },
        { "color": "green", "label": "change", "value": 1 },
        { "color": "orange", "label": "not sure", "value": 2 },
        { "color": "red", "label": "bad imagery",  "value": 3 },
      ],
    },
    '4': {
      component: 'tiles',
      defaultAnswerLabels: [
        { "color": "", "label": "not complete", "value":  0 },
        { "color": "green", "label": "complete", "value": 1 },
        { "color": "orange", "label": "maybe complete", "value": 2 },
        { "color": "red", "label": "bad imagery",  "value": 3 },
      ],
    },
    '5': {
      component: 'question',
      defaultAnswerLabels: [],
    },
    '6': {
      component: 'digitisation',
      defaultAnswerLabels: [],
    },
  },
  projectFilters: [
    {
      'attribute': 'appId',
      // 'defaultItems': [1], // HeiGIT crowdmap instance
      'defaultItems': [],
      'multiple': true,
      'show': false
    }, {
      'attribute': 'projectTopic',
      'label': 'Topic',
      'defaultItems': [],
      'multiple': true,
      'show': true
    },
    {
      'attribute': 'projectRegion',
      'label': 'Region',
      'defaultItems': [],
      'multiple': true,
      'show': true
    },
  ],
  confidenceQuestion: { 
    title: 'How certain are you?',
      slot:'confidence',
      answers: [
        { label: 'Very certain', value: 'very_certain' },
        { label: 'Certain', value: 'certain' },
        { label: 'Uncertain', value: 'uncertain' }
      ]
   },
   showInactiveProjects: false,
   filterProjectsByLocale: true,
   theme: 'default'
}

export default appConfig